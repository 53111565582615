/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  /* height: 100vh; */
  background-color: rgb(243 244 246 / 1);
}

.image {
  height: 170px;
  border-radius: 4px;
  width: 250px;
  object-fit: cover;
}

.bg_image {
}

@media screen and (max-width: 450px) {
  .image {
    height: 150px;
    width: 250px;
    object-fit: cover;
  }

  .mobile_buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 40;
    padding: 16px 10px;
  }
}
